import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalsService } from '../globals.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient, private globals: GlobalsService) {}

  public getShoes(): Observable<any> {
    return this.http.get<any>(`${environment.api}/sneakers?token=G3tSh0es4L1f3`);
  }

  public findActivity(filter = '', sortOrder = 'desc', pageNumber = 0, pageSize = 1000): Observable<any> {
    return this.http.get<any>(`${environment.api}/activity/find?token=G3tSh0es4L1f3`, {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    });
  }}
