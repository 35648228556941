import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScrapersService {
  headers;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer G3tSh0es4L1f3',
    });
  }

  runStockXScraper(data) {
    return this.http.post(`${environment.api}/scraper/stockx`, data, { headers: this.headers });
  }

  removeDuplicates() {
    return this.http.post(`${environment.api}/scraper/duplicates`, { limit: 5 }, { headers: this.headers });
  }

  removePlaceholders() {
    return this.http.get(`${environment.api}/scraper/images/placeholders/reset`, { headers: this.headers });
  }

  checkPlaceholders() {
    return this.http.post(`${environment.api}/scraper/images/placeholders/check`, {}, { headers: this.headers });
  }

  startStockXScheduler(params) {
    return this.http.post(`${environment.api}/scraper/stockx/cron`, params, { headers: this.headers });
  }

  getStockXScheduler() {
    return this.http.get(`${environment.api}/scraper/stockx/cron`, { headers: this.headers });
  }

  stopStockXScheduler() {
    return this.http.get(`${environment.api}/scraper/stockx/cron/stop`, { headers: this.headers });
  }

  getStockXSchedulerNext() {
    return this.http.get(`${environment.api}/scraper/stockx/cron/next`, { headers: this.headers });
  }

  getStadiumGoodsScheduler() {
    return this.http.get(`${environment.api}/scraper/stadiumgoods/cron`, { headers: this.headers });
  }

  stopStadiumGoodsScheduler() {
    return this.http.get(`${environment.api}/scraper/stadiumgoods/cron/stop`, { headers: this.headers });
  }

  getStadiumGoodsSchedulerNext() {
    return this.http.get(`${environment.api}/scraper/stadiumgoods/cron/next`, { headers: this.headers });
  }

  runGoatScraper(limit) {
    return this.http.post(`${environment.api}/scraper/goat`, { limit: limit }, { headers: this.headers });
  }

  runS3Scraper(limit) {
    return this.http.post(`${environment.api}/scraper/s3migration`, { limit: limit }, { headers: this.headers });
  }

  runStadiumGoodsScraper(data) {
    return this.http.post(`${environment.api}/scraper/stadiumgoods`, data, { headers: this.headers });
  }
}
