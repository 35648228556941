import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SneakerStats } from '../_models/sneaker-stats';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getStats(): Observable<any> {
    return this.http.get<any>(`${environment.api}/sneakers/sku/total?token=G3tSh0es4L1f3`);
  }
}
