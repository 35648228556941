import { Injectable } from '@angular/core';
import { Layout } from '../_models/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public layout: Layout;
  private currentLayoutSubject: BehaviorSubject<Layout>;

  constructor() {
    this.currentLayoutSubject = new BehaviorSubject<Layout>({
      isTopbarShown: false,
      isSidebarShown: false,
      isSidebarOpen: false,
      isSidebarIcon: false
    });
  }

  public get currentLayoutValue(): Layout {
    return this.currentLayoutSubject.value;
  }

  public setLayout(layout): Layout {
    this.currentLayoutSubject.next(layout);
    return layout;
  }

  public showTopbar() {
    // Show Top Bar
    let newLayout = this.currentLayoutValue;
    newLayout.isTopbarShown = true;
    this.setLayout(newLayout);
  }

  public getLayout(): Observable<Layout> {
    return this.currentLayoutSubject.asObservable();
  }

  public resetLayout() {
    this.currentLayoutSubject.next({
      isTopbarShown: false,
      isSidebarShown: false,
      isSidebarOpen: false,
      isSidebarIcon: false
    });
  }
}
