import { Injectable } from '@angular/core';
import { Observable, pipe, Subject, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap, distinctUntilChanged, debounceTime, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShoesService {
  searchSubject = new Subject();

  constructor(private http: HttpClient) {}

  public getShoes(): Observable<any> {
    return this.http.get<any>(`${environment.api}/sneakers?token=G3tSh0es4L1f3`);
  }

  public getShoesBar(): Observable<any> {
    return this.http.get<any>(`${environment.api}/sneakers/bar?token=G3tSh0es4L1f3`);
  }

  public findShoes(filter = '', sortOrder = 'asc', pageNumber = 0, pageSize = 3): Observable<any> {
    return this.http.get<any>(`${environment.api}/sneakers/find?token=G3tSh0es4L1f3`, {
      params: new HttpParams()
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString()),
    });
    //   .pipe(
    //     map(res =>  res.data)
    // );
  }

  getSearch() {
    return this.searchSubject.asObservable().pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap((searchValue) => {
        console.log('getSearch: ', searchValue);
        return this.http.get(`${environment.api}/sneakers/name/${searchValue}?token=G3tSh0es4L1f3`);
        // return of(searchValue)
      })
    );
  }

  emitShoeValue(searchShoe) {
    this.searchSubject.next(searchShoe);
  }

  searchShoe(searchTerm): Observable<any> {
    return this.http.get(`${environment.api}/sneakers/name/${searchTerm}?token=G3tSh0es4L1f3`);
  }

  deleteShoe(shoeId): Observable<any> {
    return this.http.delete(`${environment.api}/sneakers/${shoeId}?apptype=admin-portal`);
  }
  linkShoeToRedditPost(redditId: number, shoeId: number): Observable<any> {
    return this.http.post(`${environment.api}/admin/reddit/linkshoe?token=G3tSh0es4L1f3`, { redditId, shoeId });
  }

  updateRedditPost(redditPost) {
    let payload = {
      id: redditPost.id,
      silhouette: redditPost.silhouette,
      size: redditPost.size,
      condition: redditPost.condition,
      price: redditPost.price,
      shoeId: redditPost.shoeId,
    };

    console.log('Sending payload: ', payload);

    return this.http.post(`${environment.api}/admin/reddit/updatePost/${payload.id}?token=G3tSh0es4L1f3`, payload);
  }

  unlinkShoe(redditPost) {
    let payload = {
      id: redditPost.id,
      silhouette: redditPost.silhouette,
      size: redditPost.size,
      condition: redditPost.condition,
      price: redditPost.price,
      shoeId: null,
    };

    console.log('UNLINKING');

    return this.http.post(`${environment.api}/admin/reddit/updatePost/${payload.id}?token=G3tSh0es4L1f3`, payload);
  }


  getShoe(shoeId) {
    return this.http.get<any>(`${environment.api}/sneakers/admin/shoe/${shoeId}?token=G3tSh0es4L1f3`)
  }


  createShoe(values) {
    return this.http.post<any>(`${environment.api}/sneakers/admin/createShoe?token=G3tSh0es4L1f3`, values)
  }

  editShoe(values, shoeId) {
    return this.http.post<any>(`${environment.api}/sneakers/admin/editShoe/${shoeId}?token=G3tSh0es4L1f3`, values)
  }

  searchShoeOnWeb(styleId) {
    return this.http.get<any>(`${environment.api}/sneakers/websearch/${styleId}?token=G3tSh0es4L1f3`)
  }
}
