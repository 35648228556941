import { Component, OnInit } from '@angular/core';
import { LayoutService} from './_services/layout.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private layout: LayoutService) {

  }

  ngOnInit() {
    // this.layout.getLayout().subscribe((result) => {
    //   console.log(result);
    // });

  }



}
