import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { map } from "rxjs/operators";
import { Observable, of as observableOf, merge, of, BehaviorSubject } from "rxjs";
import { ShoesService } from "src/app/_services/shoes.service";
import { catchError, finalize } from "rxjs/operators";
import { Shoe } from "../../_models/shoe";

export class ShoesTableDataSource extends DataSource<Shoe> {
  data: Shoe[] = [];
  paginator: MatPaginator;
  sort: MatSort;

  private shoeSubject = new BehaviorSubject<Shoe[]>([]);
  private shoeSubjectCount = new BehaviorSubject<number>(0);

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.shoeSubjectCount.asObservable();

  constructor(private shoes: ShoesService) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Shoe[]> {
    return this.shoeSubject.asObservable();

    // return merge(...dataMutations).pipe(
    //   map(() => {
    //     return this.getPagedData(this.getSortedData([...this.data]));
    //   })
    // );
  }



  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.shoeSubject.complete();
    this.loadingSubject.complete();
    this.shoeSubjectCount.complete();
  }

public findShoes(filter = '', sortOrder = 'desc', pageNumber = 0, pageSize = 10) {
    this.loadingSubject.next(true);

    this.shoes.findShoes(filter, sortOrder, pageNumber, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)))
      .subscribe(shoes => {

        console.log(shoes);
        this.shoeSubject.next(shoes.data);
        this.shoeSubjectCount.next(shoes.count);
      });
  }

  loadShoes() {
    this.loadingSubject.next(true);

    this.shoes.getShoes().pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)))
      .subscribe(shoes => {
        console.log(shoes);
        return this.shoeSubject.next(shoes)});

  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: Shoe[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: Shoe[]) {
    if (!this.sort.active || this.sort.direction === "") {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === "asc";
      switch (this.sort.active) {
        case "colorway":
          return compare(a.colorway, b.colorway, isAsc);
        case "brand":
          return compare(a.brand, b.brand, isAsc);
        case "name":
          return compare(a.name, b.name, isAsc);
        case "id":
          return compare(+a.id, +b.id, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
