<div class="boxed-form">
  <mat-card id="login">
    <mat-card-title>Register for Unbox</mat-card-title>
    <app-alert></app-alert>

    <mat-card-content>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="First Name" formControlName="firstName" />
            <mat-error *ngIf="submitted && form.firstName.errors?.required">Please enter first name.</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="Last Name" formControlName="lastName" />
            <mat-error *ngIf="submitted && form.lastName.errors?.required">Please enter last name.</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="email" matInput placeholder="Email" formControlName="username" />
            <mat-error *ngIf="submitted && form.username.errors?.required">Please enter email.</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password" />
            <mat-error *ngIf="submitted && form.password.errors?.required">Please enter password.</mat-error>
            <mat-error *ngIf="submitted && form.password.errors?.minlength">Password must be at least 8 characters.</mat-error>
          </mat-form-field>
        </p>

        <p>
            <mat-form-field>
              <input type="password" matInput placeholder="Access Code" formControlName="accessCode" />
              <mat-error *ngIf="submitted && form.accessCode.errors?.required">Please enter access code.</mat-error>
            </mat-form-field>
          </p>

        <div class="form-group d-flex justify-content-between">
          <button [disabled]="loading" mat-button>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Register
          </button>
          <a routerLink="/login" class="btn btn-link" mat-button> Back to login &gt;</a>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
