<div class="progress-spinner" *ngIf="loading"><mat-spinner></mat-spinner></div>
<table *ngIf="!loading" role="table">
  <thead role="rowgroup">
    <tr role="row">
      <th></th>
      <th class="col" role="columnheader">Style ID</th>

      <th class="col" role="columnheader">Title</th>
      <th class="col" role="columnheader">Type</th>
      <th class="col" role="columnheader">Size</th>
      <th class="col" role="columnheader">Condition</th>
      <th class="col" role="columnheader">Price</th>
      <th class="col" role="columnheader">Shoe</th>
      <th class="col" role="columnheader">Linking Button</th>
    </tr>
  </thead>
  <tbody role="rowgroup">
    <tr *ngFor="let listing of redditPosts" [attr.id]="listing.id" role="row">
      <td role="cell" class="col" style="width: 200px">
        <img src="{{ listing.image }}" (click)="openDialog(listing.image)" />
      </td>
      <td role="cell" class="col" style="width: 200px">
        <img src="{{ listing.shoeImage }}" (click)="openDialog(listing.shoeImage)" />
        <div>{{ listing.styleId }}</div>
        <button *ngIf="listing.shoeId" class="mr-2 btn btn-dark" [disabled]="listing.loading" (click)="unlinkShoe(listing)">
          <span *ngIf="!listing.loading">Unlink shoe</span>
          <div class="spinner-border" role="status" *ngIf="listing.loading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </td>
      <td role="cell" class="col">{{ listing.title }}</td>
      <td role="cell" class="col">
        <mat-form-field appearance="outline">
          <mat-select [(value)]="listing.filter">
            <mat-option *ngFor="let aType of types" [value]="aType.value">
              {{ aType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td role="cell" class="col">
        <mat-form-field appearance="outline">
          <mat-select [(value)]="listing.size">
            <mat-option *ngFor="let size of sizes" [value]="size.value">
              {{ size.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td role="cell" class="col">
        <mat-form-field appearance="outline">
          <mat-select [(value)]="listing.condition">
            <mat-option *ngFor="let condition of conditions" [value]="condition.value">
              {{ condition.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td role="cell" class="col">
        <p contenteditable="true">{{ listing.price | currency }}</p>
      </td>
      <td role="cell" class="col">
        <div class="shoe-search-form">
          <!-- <mat-spinner *ngIf="listing.loading" strokeWidth="5" [diameter]="35" class="spinner"></mat-spinner> -->

          <form class="shoeSearchForm">
            <mat-form-field appearance="outline">
              <input
                #box
                matInput
                type="text"
                placeholder="Search shoe"
                aria-label="Shoe"
                matInput
                [value]="listing.shoeTitle"
                (input)="searchShoe(box.value, listing)"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [panelWidth]="350"
                (optionSelected)="linkShoeToRedditPost($event.option.id, $event.option.value, listing)"
              >
                <mat-option *ngFor="let shoe of shoesFound" [value]="shoe.title" [id]="shoe.id">
                  <img style="vertical-align: middle; margin-right: 5px" aria-hidden src="{{ shoe.imageUrl }}" height="30" />
                  <span>{{ shoe.title }}</span> <br />
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      </td>

      <td role="cell" class="col">
        <button class="mr-2 btn btn-dark" [disabled]="listing.loading" (click)="updateRedditPost(listing)">
          <span *ngIf="!listing.loading">Save</span>
          <div class="spinner-border" role="status" *ngIf="listing.loading">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </td>
    </tr>
  </tbody>
</table>

<!-- <div class="grid" *ngIf="!loading">
  <div *ngFor="let col of tableColumns" class="col">
    <div class="col-content">
      <strong>{{ col }}</strong>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content"><img src="{{ listing.image }}" /></div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">{{ listing.title }}</div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <mat-form-field appearance="outline">
        <mat-select [(value)]="listing.filter">
          <mat-option *ngFor="let aType of types" [value]="aType.value">
            {{ aType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <mat-form-field appearance="outline">
        <mat-select [(value)]="listing.size">
          <mat-option *ngFor="let size of sizes" [value]="size.value">
            {{ size.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <mat-form-field appearance="outline">
        <mat-select [(value)]="listing.condition">
          <mat-option *ngFor="let condition of conditions" [value]="condition.value">
            {{ condition.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <p contenteditable="true">{{ listing.price | currency }}</p>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <form class="shoeSearchForm">
        <mat-form-field appearance="outline">
          <input
            #box
            matInput
            type="text"
            placeholder="Search shoe"
            aria-label="Shoe"
            matInput
            [value]="listing.shoe"
            (input)="searchShoe(box.value)"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let shoe of shoesFound" [value]="shoe.shoe" [id]="shoe.id">
              <img style="vertical-align: middle; margin-right: 5px" aria-hidden src="{{ shoe.imageUrl }}" height="30" />
              <span>{{ shoe.shoe }}</span> <br />
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <p>{{ listing.styleId }}</p>
    </div>
  </div>

  <div class="col">
    <div *ngFor="let listing of redditPosts" class="col-content">
      <button class="mr-2 btn btn-dark" [disabled]="listing.loading" (click)="updateRedditPost(listing)">
        <span *ngIf="!listing.loading">Save</span>
        <div class="spinner-border" role="status" *ngIf="listing.loading">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</div> -->
