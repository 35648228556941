import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockXDialogComponent } from '../stockx-dialog/stockx-dialog.component';
import { DialogData } from '../scrapers.component';

@Component({
  selector: 'app-sg-dialog',
  templateUrl: './sg-dialog.component.html',
  styleUrls: ['./sg-dialog.component.scss']
})
export class SgDialogComponent implements OnInit {

  sgParams;



  constructor(
    public dialogRef: MatDialogRef<SgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    this.sgParams = {
      numShoes: 200,
      batchSize: 50,
      minutes: 5
    };
  }

  getNumScrapes(){
    return Math.ceil(this.sgParams.numShoes / this.sgParams.batchSize)
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
