import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { RedditPost, RedditResponse } from '../_models/redditPost';

@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  constructor(private http: HttpClient) {}

  fetchRedditPosts(): Observable<RedditPost[]> {
    return this.http.get(`${environment.api}/admin/reddit/getPosts`).pipe(
      map((data: RedditResponse) => data.posts));
  }
}
