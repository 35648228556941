<h1 mat-dialog-title>Run Goat Scraper</h1>
<div mat-dialog-content>
  <div class="form-group mr-2">
    <mat-label>Limit</mat-label>
    <input class="form-control" placeholder="Starting page #" [(ngModel)]="goatParams.limit" />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button  [mat-dialog-close]="goatParams" cdkFocusInitial>Run Goat Migration</button>
</div>
