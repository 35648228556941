<h1 mat-dialog-title>Run StockX Scraper</h1>
<div mat-dialog-content>
  <div class="d-flex align-items-center">
    <div class="form-group mr-2">
      <div class="input-group mr-2">
        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="stockXParams.date" ngbDatepicker #d="ngbDatepicker" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group mr-2">
      <div class="input-group">
        <ngb-timepicker [(ngModel)]="stockXParams.time" class="mr-2"></ngb-timepicker>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="form-group mr-2">
      <mat-label>Start page</mat-label>
      <input class="form-control" placeholder="Starting page #" [(ngModel)]="stockXParams.startPage" />
    </div>
    <div class="form-group mr-2">
      <mat-label>End Page </mat-label>
      <input class="form-control" placeholder="Stopping page #" [(ngModel)]="stockXParams.endPage" />
    </div>
  </div>

  <div class="form-group mr-2">
    <label>Order</label><br />
    <select class="selectpicker" [(ngModel)]="stockXParams.order">
      <option value="">--Select Order--</option>
      <option *ngFor="let dateOrder of dateOrders" [value]="dateOrder.value">
        {{dateOrder.name}}
      </option>
    </select>
  </div>
  <div class="form-group mr-2">
    <mat-checkbox class="mr-3" [(ngModel)]="stockXParams.parallel">Parallel </mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="loadingStockX" [mat-dialog-close]="stockXParams" cdkFocusInitial>Run Stockx</button>
</div>
