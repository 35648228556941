import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../scrapers.component';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-stockx-scheduler',
  templateUrl: 'stockx-scheduler-dialog.component.html',
})
export class StockXSchedulerDialogComponent {
  stockXParams;
  loadingStockX;
  numShoes = 200;
  dateOrders = [
    { name: 'In the future', value: 'ASC' },
    { name: 'In the past', value: 'DESC' },
  ];

  constructor(
    public dialogRef: MatDialogRef<StockXSchedulerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private calendar: NgbCalendar
  ) {
    const numPages = Math.ceil(this.numShoes / 40);

    this.stockXParams = {
      start_page: 1,
      end_page: numPages,
      order: 'DESC',
      parrallel: true,
      minute: '0',
      hour: '4',
      dayOfMonth: '*',
      month: '*',
      dayOfWeek: '*',
    };

    console.log(this.stockXParams);
  }

  changeNumShoes($event) {
    const numPages = Math.ceil(this.numShoes / 40);
    this.stockXParams.endPage = numPages;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
