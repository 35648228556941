import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../scrapers.component';

@Component({
  selector: 'app-goat-dialog',
  templateUrl: './goat-dialog.component.html',
  styleUrls: ['./goat-dialog.component.scss']
})
export class GoatDialogComponent implements OnInit {

  goatParams;

  constructor(
    public dialogRef: MatDialogRef<GoatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

    this.goatParams = {
      limit: 50,
    };
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
