<!-- Search form -->
<div class="md-form mt-0">
  <mat-form-field>
    <i>Search</i>
    <input matInput class="form-control" type="text" aria-label="Search" #input />
  </mat-form-field>
</div>

<div *ngIf="dataSource.loading$ | async">
  <div class="placeload">
    <div class="image-placeholder loads"></div>
    <div class="w-100 mt-15">
      <div class="d-flex flex-column">
        <div class="content-shape loads"></div>
        <div class="content-shape loads"></div>
      </div>
    </div>
  </div>
  <div class="placeload">
    <div class="image-placeholder loads"></div>
    <div class="w-100 mt-15">
      <div class="d-flex flex-column">
        <div class="content-shape loads"></div>
        <div class="content-shape loads"></div>
      </div>
    </div>
  </div>
  <div class="placeload">
    <div class="image-placeholder loads"></div>
    <div class="w-100 mt-15">
      <div class="d-flex flex-column">
        <div class="content-shape loads"></div>
        <div class="content-shape loads"></div>
      </div>
    </div>
  </div>
</div>

<div class="example-container shoe-table">
  <div class="example-loading-shade" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    class="full-width-table"
    multiTemplateDataRows
    aria-label="Elements"
    matSort
    matSortActive="updateAt"
    matSortDirection="desc"
    [hidden]="dataSource.loading$ | async"
  >
    <ng-container matColumnDef="imageUrl">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <img [src]="element.imageUrl ? element.imageUrl : '../../../assets/images/placeholder_shoe.png'" alt="" class="shoe-thumb" />
      </td>
    </ng-container>

    <ng-container matColumnDef="shoe">
      <th mat-header-cell *matHeaderCellDef>shoe</th>
      <td mat-cell *matCellDef="let element">
        {{ element.shoe }}<br />
        {{ element.styleId }}<br />
        {{ element.title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef>brand</th>
      <td mat-cell *matCellDef="let element">
        {{ element.brand }}
      </td>
    </ng-container>

    <ng-container matColumnDef="colorway">
      <th mat-header-cell *matHeaderCellDef>colorway</th>
      <td mat-cell *matCellDef="let element">
        {{ element.colorway }}
      </td>
    </ng-container>

    <ng-container matColumnDef="releaseDateTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>release date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.releaseDateTime | amDateFormat: 'YYYY-MM-DD HH:mm' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createAt | amDateFormat: 'YYYY-MM-DD HH:mm' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updateAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.updateAt | amDateFormat: 'YYYY-MM-DD HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let shoe" [attr.colspan]="columnsToDisplay.length">
        <div class="shoe-detail" [@detailExpand]="shoe == expandedRow ? 'expanded' : 'collapsed'">
          <div class="shoe-detail-col">
            <img [src]="shoe.imageUrl" alt="" class="shoe-image" />
          </div>
          <div class="shoe-detail-col">
            <div class="shoe-item"><span class="shoe-label">Name: </span> {{ shoe.shoe }}</div>
            <div class="shoe-item">
              <span class="shoe-label">Brand: </span>
              {{ shoe.brand }}
            </div>
            <div class="shoe-item"><span class="shoe-label">Category: </span> {{ shoe.category }}</div>
            <div class="shoe-item"><span class="shoe-label">Gender: </span>{{ shoe.gender }}</div>
            <div class="shoe-item"><span class="shoe-label">Colorway: </span> {{ shoe.colorway }}</div>
            <div class="shoe-item"><span class="shoe-label">Style: </span> {{ shoe.styleId }}</div>
            <div class="shoe-item"><span class="shoe-label">SKUs: </span> {{ shoe.SKU }}</div>
            <div class="shoe-item"><span class="shoe-label">Release: </span> {{ shoe.releaseDateTime | amCalendar }}</div>
            <div class="shoe-item"><span class="shoe-label">Retail: </span> $ {{ shoe.retailPrice }}</div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <!-- <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      class="shoe-row"
      [class.example-expanded-row]="expandedRow === row"
      (click)="expandedRow = expandedRow === row ? null : row; "
    ></tr> -->
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      class="shoe-row"
      (click)="goToPage(row.id)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="shoe-detail-row"></tr>
  </table>

  <mat-paginator
    [length]="dataSource.count$ | async"
    [pageSize]="10"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [hidden]="dataSource.loading$ | async"
  ></mat-paginator>
</div>
