<!-- Top Toolbar -->
<mat-toolbar color="primary" *ngIf="isTopbarShown">
  <mat-toolbar-row class="d-flex">
    <!-- <button mat-icon-button>
        <mat-icon *ngIf="isSidebarIcon" (click)="toggleSidebar()">menu</mat-icon>
      </button> -->
    <img src="assets/images/logo_white.png" alt="" />

    <span class="menu-spacer"></span>
    <div>
      <a mat-button [routerLink]="'/dashboard'"> Dashboard </a>
      <a mat-button [routerLink]="'/scrapers'"> Scrapers</a>
      <a mat-button [routerLink]="'/listings'">Listings</a>
      <!-- <a mat-button [routerLink]="'/duplicates'"> Duplicates</a> -->
    </div>
    <div class="separator"></div>
    <div>
      <a mat-button (click)="logout()"> Logout </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
