import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../scrapers.component';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-stockx',
  templateUrl: 'stockx-dialog.component.html'
})
export class StockXDialogComponent {
  stockXParams;
  loadingStockX;

  constructor(
    public dialogRef: MatDialogRef<StockXDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private calendar: NgbCalendar
  ) {
    this.stockXParams = {
      date: this.calendar.getToday(),
      time: { hour: 13, minute: 30 },
      startPage: 1,
      endPage: 10,
      order: 'DESC',
      parrallel: false
    };
  }

  dateOrders = [
    { name: 'ASC', value: 'ASC' },
    { name: 'DESC', value: 'DESC' }
  ];

  cancel(): void {
    this.dialogRef.close();
  }
}
