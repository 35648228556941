<h1 mat-dialog-title>Run StockX Scheduler</h1>
<div mat-dialog-content>
  <div class="d-flex">
    <div class="form-group mr-2">
      <mat-label>Number of Shoes</mat-label>
      <select class="selectpicker" [(ngModel)]="numShoes" (ngModelChange)="changeNumShoes($event)">
        <option value="40">
          40
        </option>
        <option value="80">
          80
        </option>
        <option value="120">
          120
        </option>
        <option value="160">
          160
        </option>
        <option value="200">
          200
        </option>
        <option value="240">
          240
        </option>
        <option value="280">
          280
        </option>
        <option value="320">
          320
        </option>
        <option value="360">
          360
        </option>
        <option value="400">
          400
        </option>
      </select>
    </div>
  </div>

  <div class="form-group mr-2">
    <label>Order</label><br />
    <select class="selectpicker" [(ngModel)]="stockXParams.order">
      <option value="">--Select Order--</option>
      <option *ngFor="let dateOrder of dateOrders" [value]="dateOrder.value">
        {{ dateOrder.name }}
      </option>
    </select>
  </div>

  <div class="alert alert-primary" role="alert">
    This scheduler will run StockX scraper every night at 4am EST from 7 days in advance in {{stockXParams.order}} order.
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [disabled]="loadingStockX" [mat-dialog-close]="stockXParams" cdkFocusInitial>Run Stockx Scheduler</button>
</div>
