import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ShoesListComponent } from './shoes/shoes-list/shoes-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { RegisterComponent } from './register/register.component';
import { ActivityComponent } from './activity/activity.component';
import { ScrapersComponent } from './scrapers/scrapers.component';
import { MarketListingsComponent } from './market-listings/market-listings.component';
import { CreateShoeComponent } from './create-shoe/create-shoe.component';
import { ShoeEditComponent } from './shoes/shoe-edit/shoe-edit.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scrapers',
    component: ScrapersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'listings',
    component: MarketListingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activity',
    component: ActivityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shoes',
    component: ShoesListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-shoe',
    component: CreateShoeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shoe/edit/:shoeId',
    component: ShoeEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
