


import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShoesService } from '../../_services/shoes.service';
import * as moment from 'moment';
import { switchMap, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Location } from '@angular/common'
import { AlertService } from 'src/app/_services/alert.service';

interface Sneaker {
  styleId: number;
  imageUrl: string;
  title: string;
  name: string;
  brand: string;
  category: string;
  colorway: string;
  gender: string;
  releaseDate: Date;
  retailPrice: number;
  shoe: string;
  shortDescription: string;
  tickerSymbol: string;
  urlKey: string;
  year: number;
  SKU: number;
  didSearchSKU: boolean;
  isBroken: boolean;
  designer: string;
  mainColor: string;
  tech: string;
  upperMaterial: string;
  silhouette: string;
}



@Component({
  selector: 'app-shoe-edit',
  templateUrl: './shoe-edit.component.html',
  styleUrls: ['./shoe-edit.component.scss'],
})
export class ShoeEditComponent implements OnInit {
  editShoeForm: FormGroup;
  alertMessage;
  searchingShoe: boolean = false;
  loadingShoe: boolean = false;
  dateInfo;
  shoeId;
  shoe$: Observable<any>;
  loadingDeleteShoe: boolean = false;


  get imageUrl(): AbstractControl {
    return this.editShoeForm.controls.imageUrl;
  }

  constructor(
    private fb: FormBuilder,
    private shoeService: ShoesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.editShoeForm = this.fb.group({
      styleId: ['', [Validators.required]],
      imageUrl: [''],
      title: ['', [Validators.required]],
      name: ['', [Validators.required]],
      brand: [''],
      category: [''],
      colorway: [''],
      gender: [''],
      releaseDate: [''],
      retailPrice: [''],
      shoe: [''],
      shortDescription: [''],
      tickerSymbol: [''],
      urlKey: [''],
      year: [''],
      SKU: [''],
      didSearchSKU: [''],
      isBroken: [''],
      designer: [''],
      mainColor: [''],
      tech: [''],
      upperMaterial: [''],
      silhouette: [''],
    });

    this.shoe$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.shoeId = Number(params.get('shoeId'));
        console.log("Found shoe: ", this.shoeId)
        return this.shoeService.getShoe(this.shoeId);
      })
    );

    this.shoe$.subscribe(shoe => {
      this.loadingShoe = false;
      console.log("got shoe:", shoe);
      let releaseDateTime = moment(shoe.releaseDateTime).toDate()

      this.patchValuesIntoForm(shoe, releaseDateTime)
    });

  }

  getErrorMsg(control: AbstractControl, input: string) {
    return control.hasError('required') ? `${input} is required` : '';
  }

  deleteShoe(){

    this.loadingDeleteShoe = true;
    this.shoeService.deleteShoe(this.shoeId).subscribe(result => {
      this.loadingDeleteShoe = false;
      console.log(result);
      this.loadingShoe = false;

      if(result.success == true){
        this.alertService.success(`Shoe deleted.`, true);
        this.router.navigate(['/dashboard']);
      }

    }, (error)=>{
      this.loadingDeleteShoe = false;
      this.alertService.error(`Error deleting shoe.`, true);
    })

  }

  patchValuesIntoForm(shoe, releaseDateTime){


    this.editShoeForm.patchValue({
      releaseDate: releaseDateTime,
      name: shoe.name,
      styleId: shoe.styleId,
      imageUrl: shoe.imageUrl,
      title: shoe.title,
      brand: shoe.brand,
      category: shoe.category,
      colorway: shoe.colorway,
      gender: shoe.gender,
      retailPrice: shoe.retailPrice,
      shoe: shoe.shoe,
      shortDescription: shoe.shortDescription,
      tickerSymbol: shoe.tickerSymbol,
      urlKey: shoe.urlKey,
      year: shoe.year,
      SKU: shoe.SKU,
      didSearchSKU: shoe.didSearchSKU,
      isBroken: shoe.isBroken,
      designer: shoe.designer,
      mainColor: shoe.mainColor,
      tech: shoe.tech,
      upperMaterial: shoe.upperMaterial,
      silhouette: shoe.silhouette
    });

  }

  getMarketplaceInfo(styleId){

    this.searchingShoe = true;
    this.shoeService.searchShoeOnWeb(styleId).subscribe(shoes => {
      if(shoes.length > 0){

        let releaseDateTime = moment(shoes[0].releaseDateTime, "YYYY-DD-MM").toDate()

        this.patchValuesIntoForm(shoes[0], releaseDateTime)
        this.alertService.success(`We found ${shoes[0].name} from web or in database. It has been added to database.`, true);
      }
      this.searchingShoe = false;
    });
  }

  cancel(){
    this.router.navigate(['/dashboard'])
  }

  goBack(){
    this.location.back();
  }

  onSubmit(formValues) {
    this.loadingShoe = true;

    if(this.editShoeForm.invalid){
      this.loadingShoe = false;

      return;
    }

    this.shoeService.editShoe(formValues, this.shoeId).subscribe(result => {
      console.log(result);
      this.loadingShoe = false;

      if(result.success == true){
        this.alertService.success(`Shoe edited.`);
        // this.router.navigate(['/dashboard'])
      }


    }, (error)=>{
      this.loadingShoe = false;


      this.alertMessage = {
        type: "error",
        text: error
      }
      console.error("Error creating shoe: ", error);

    })
  }
}

