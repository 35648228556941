import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  // apiServer = 'http://localhost:3009';
  apiServer = 'https://api.unboxed-app.com';

  constructor() { }

}
