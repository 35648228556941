import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe((message) => {
      this.message = message;

      // Get last stored message
      // if(!this.message){
      //   this.message = this.alertService.alertMessage
      // };

      console.log('Found Alert Message: ', this.message);

    });
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }
}
