import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, first } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';
import { LayoutService } from '../_services/layout.service';
import { DashboardService } from '../_services/dashboard.service';

import { User } from '../_models/user';
import { Layout } from '../_models/layout';
import { Subscription } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  currentUser: User;
  // currentUserSubscription: Subscription;
  users: User[] = [];
  newLayout: Layout;
  loadingStats = false;

  constructor(
    private authenticationService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private layout: LayoutService,
    private dashboard: DashboardService,
    private router: Router
  ) {
  }

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', stat: 20, cols: 2, rows: 1 },
          { title: 'Card 2', stat: 20, cols: 2, rows: 1 },
          { title: 'Card 3', stat: 20, cols: 2, rows: 1 },
          { title: 'Card 4', stat: 20, cols: 2, rows: 1 },
        ];
      }

      return [
        { title: 'Card 1', stat: 20, cols: 1, rows: 1 },
        { title: 'Card 2', stat: 20, cols: 1, rows: 1 },
        { title: 'Card 3', stat: 20, cols: 1, rows: 1 },
        { title: 'Card 4', stat: 20, cols: 1, rows: 1 },
      ];
    })
  );

  ngOnInit() {
    // this.loadAllUsers();
    this.layout.showTopbar();

    this.cards = this.dashboard.getStats();

    this.cards.subscribe((results: any) => {
      if (results.success === false) {
        this.logout();
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.layout.resetLayout();
    this.router.navigate(['login']);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.currentUserSubscription.unsubscribe();
  }

}
