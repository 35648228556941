<div class="boxed-form">
  <mat-card class="login-card">
    <mat-card-title class="bg-dark p-15">
      <img src="assets/images/logo_white.png" alt="">
    </mat-card-title>

    <app-alert></app-alert>
    <mat-card-content class="m-15 mb-15">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <p>
          <mat-form-field>
            <input type="email" matInput placeholder="Username" formControlName="username" />
            <mat-error *ngIf="form.username.invalid">{{ getErrorMessage() }}</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Password" formControlName="password" />
            <mat-error *ngIf="form.password.invalid">Invalid password</mat-error>
          </mat-form-field>
        </p>

        <div class="text-center">
          <button class="mr-2 btn btn-dark fixed-width-175"  [disabled]="loading">
            <span *ngIf="!loading">Login</span>
            <div class="spinner-border" role="status" *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
<!--
          <button [disabled]="loading" mat-button>
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Login
          </button> -->
          <!-- <button routerLink="/register" class="btn btn-link" mat-button>Register</button> -->
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
