import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ShoesTableDataSource } from './shoes-table-datasource';
import { ShoesService } from 'src/app/_services/shoes.service';
import { Shoe } from '../../_models/shoe';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fromEvent, merge } from 'rxjs';
import { Router } from '@angular/router';
// import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-shoes-table',
  templateUrl: './shoes-table.component.html',
  styleUrls: ['./shoes-table.component.scss'],
  animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0', padding: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])]
})
export class ShoesTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Shoe>;
  @ViewChild('input') input: ElementRef;

  dataSource: ShoesTableDataSource = new ShoesTableDataSource(this.shoes);

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  columnsToDisplay = ['imageUrl', 'shoe', 'brand', 'colorway', 'releaseDateTime', 'createAt', 'updateAt'];
  expandedRow: Shoe | null;

  constructor(private shoes: ShoesService, private router: Router) {}

  ngOnInit() {
    this.dataSource.findShoes('', 'desc', 0, 10);
  }


  ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe((result) => {
        console.log(result)
        this.paginator.pageIndex = 0
      });


      merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadShoesPage())
            )
            .subscribe();


    // server-side search
      fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadShoesPage();
        })
      )
      .subscribe();
  }

  goToPage(shoeId){
    console.log("Going to id: ", shoeId);
    this.router.navigate([`/shoe/edit/${shoeId}`])
  }

  loadShoesPage() {
    // console.log("loadShoesPage", this.paginator.pageIndex, this.paginator.pageSize);
    this.dataSource.findShoes(this.input.nativeElement.value, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }
}
