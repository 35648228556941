<div class="p-12">
  <div class="grid-container">
    <!-- <div class="d-flex">
      <h1 class="mat-h1">Dashboard</h1>
  </div> -->

    <mat-grid-list cols="4" rowHeight="200px">
      <!--  Loading -->
      <div *ngIf="!(cards | async)">
        <mat-grid-tile>
          <mat-card class="dashboard-card placeload loads"></mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-card class="dashboard-card placeload loads"></mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-card class="dashboard-card placeload loads"></mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-card class="dashboard-card placeload loads"></mat-card>
        </mat-grid-tile>
      </div>

      <mat-grid-tile *ngFor="let card of cards | async" [colspan]="card.cols" [rowspan]="card.rows">
        <mat-card class="dashboard-card">
          <mat-card-header>
            <mat-card-title>
              <!-- <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>Expand</button>
              <button mat-menu-item>Remove</button>
            </mat-menu> -->
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <div class="card-stat">{{ card.stat }}</div>
          </mat-card-content>
          <mat-card-footer>
            <div class="card-footer">
              {{ card.title }}
            </div>
          </mat-card-footer>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div>
    <app-shoes-bar-chart></app-shoes-bar-chart>
  </div>

  <button class="btn btn-dark" routerLink="/create-shoe">Create shoe</button>

  <div class="shoes-table">
    <app-shoes-table></app-shoes-table>
  </div>
</div>
