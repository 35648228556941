import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  imgLink: string;
}

@Component({
  selector: 'app-shoe-img-popup',
  templateUrl: './shoe-img-popup.component.html',
  styleUrls: ['./shoe-img-popup.component.scss'],
})
export class ShoeImgPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ShoeImgPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
  onNoClick(): void {
    this.dialogRef.close();
  }
}
