<h1 mat-dialog-title>Run Stadium Goods Scraper</h1>
<div mat-dialog-content>
  <div class="form-group mr-2">
    <mat-label>Number of shoes</mat-label>
    <input class="form-control" placeholder="Number of Shoes" [(ngModel)]="sgParams.numShoes" />
  </div>
  <div class="form-group mr-2">
    <mat-label>Batch Size</mat-label>
    <input class="form-control" placeholder="Batch Size" [(ngModel)]="sgParams.batchSize" />
  </div>
  <div class="form-group mr-2">
    <mat-label>Minutes apart</mat-label>
    <input class="form-control" placeholder="Minutes" [(ngModel)]="sgParams.minutes" />
  </div>
</div>
<div class="alert alert-primary" role="alert">
  This scraper will scrape in batches of {{sgParams.batchSize}} shoes every {{sgParams.minutes}} minutes for {{getNumScrapes()}} times. Totaling to {{sgParams.numShoes}} shoes.
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="sgParams" cdkFocusInitial>Run Stadium Goods</button>
</div>
