import { Component, OnInit } from '@angular/core';
import { ShoesService } from 'src/app/_services/shoes.service';

@Component({
  selector: 'app-shoes-bar-chart',
  templateUrl: './shoes-bar-chart.component.html',
  styleUrls: ['./shoes-bar-chart.component.scss']
})
export class ShoesBarChartComponent implements OnInit {

    // Bar Chart
    public barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true
    };
    public barChartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    public barChartType = 'bar';
    public barChartLegend = true;
    public barChartData = [
      {data: [], label: ''}
    ];

  constructor(private shoesService: ShoesService) { }

  ngOnInit(): void {

    this.shoesService.getShoesBar().subscribe((result)=>{
      this.barChartData = result;

    });
  }

}
