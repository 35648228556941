<div class="p-12">
  <div class="grid-container flex-row align-items-center mt-4">
    <div class="d-flex">
      <h1 class="mat-h1 mr-2">Scrapers</h1>
      <mat-spinner color="primary" diameter="25" *ngIf="loadingActivity"></mat-spinner>
    </div>
    <div>
      <div class="alert alert-success" role="alert" *ngIf="successMsg.isShown">
        {{ successMsg.message }}
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="errorMsg.isShown">
        {{ errorMsg.message }}
      </div>
    </div>
    <section class="mt-4">
      <div class="d-flex">
        <button class="mr-2 btn btn-dark fixed-width-175" [disabled]="loadingStockX" (click)="openStockXDialog()">
          <span *ngIf="!loadingStockX">StockX</span>
          <div class="spinner-border" role="status" *ngIf="loadingStockX">
            <span class="sr-only">Loading...</span>
          </div>
        </button>

        <button class="mr-2 btn btn-dark fixed-width-175" (click)="openSgDialog()" [disabled]="loadingStadiumGoods">
          <span *ngIf="!loadingStadiumGoods">Stadium Goods</span>
          <div class="spinner-border" role="status" *ngIf="loadingStadiumGoods">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <button class="mr-2 btn btn-dark fixed-width-175" (click)="openGoatDialog()" [disabled]="loadingGoat">
          <span *ngIf="!loadingGoat">Goat</span>
          <div class="spinner-border" role="status" *ngIf="loadingGoat">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <button class="mr-2 btn btn-dark fixed-width-175" (click)="openS3Dialog()" [disabled]="loadingS3">
          <span *ngIf="!loadingS3">S3 Image Migration</span>
          <div class="spinner-border" role="status" *ngIf="loadingS3">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <button class="mr-2 btn btn-dark fixed-width-175" (click)="removeDuplicates()" [disabled]="loadingDedupe">
          <span *ngIf="!loadingDedupe">Remove Deplicates</span>
          <div class="spinner-border" role="status" *ngIf="loadingDedupe">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <button class="mr-2 btn btn-dark fixed-width-175" (click)="removePlaceholders()" [disabled]="loadingDedupe">
          <span *ngIf="!loadingPlaceholder">Remove Placeholder Images</span>
          <div class="spinner-border" role="status" *ngIf="loadingPlaceholder">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </section>

    <section class="mt-4">
      <h1 class="mat-h1 mr-2">Scheduler</h1>
      <button
        class="mr-2 btn btn-dark fixed-width-175"
        [disabled]="loadingStockXScheduler"
        (click)="openStockXSchedulerDialog()"
        *ngIf="!stockXSchedulerData"
      >
        <span *ngIf="!loadingStockXScheduler">Set StockX Scheduler</span>
        <div class="spinner-border" role="status" *ngIf="loadingStockXScheduler">
          <span class="sr-only">Loading...</span>
        </div>
      </button>

      <div class="alert alert-secondary mt-4" role="alert" *ngIf="stockXSchedulerData">
        <div>
          <h5>Scheduled StockX Scraper</h5>
        </div>
        <p class="pt-2">
          Scrape {{ stockXSchedulerData.data.params.end_page * 40 }} Shoes from 7 days in future to
          {{ stockXSchedulerData.data.params.order == 'DESC' ? ' the past' : ' the future' }}. Running every night at 4 am.
        </p>
        <p></p>
        <button class="mr-2 btn btn-dark" [disabled]="loadingStockXScheduler" (click)="stopStockXScheduler()">
          Remove
        </button>
      </div>

      <div class="alert alert-secondary mt-4" role="alert" *ngIf="stadiumGoodsScheduler">
        <div>
          <h5>Scheduled Stadium Scraper</h5>
        </div>
        <p class="pt-2">
          Scraped {{stadiumGoodsScheduler.data.params.batchSize * stadiumGoodsScheduler.data.params.counter}} of {{stadiumGoodsScheduler.data.params.numShoes}} shoes. We are currently scraping {{stadiumGoodsScheduler.data.params.batchSize}} shoes every {{stadiumGoodsScheduler.data.params.minutes}} min. Next scrape is {{stadiumGoodsScheduler.data.params.nextJob | amCalendar}}.
        </p>
        <p></p>
        <!-- <button class="mr-2 btn btn-dark" [disabled]="loadingStockXScheduler" (click)="stopStadiumGoodsScheduler()">
          Stop
        </button> -->
      </div>
    </section>

    <section class="mt-4">
      <h1 class="mat-h1 mr-2">Activity</h1>
      <table mdbTable class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let activityLog of activityLogs">
            <th scope="row">{{ activityLog.id }}</th>
            <td>{{ activityLog.styleId }}</td>
            <td>{{ activityLog.status }}</td>
            <td>{{ activityLog.type }}</td>
            <!-- <td>{{ activityLog.notes }} <br> {{ activityLog.log }}</td> -->
            <td>{{ activityLog.notes }}</td>
            <td>{{ activityLog.createdAt | amCalendar }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</div>
