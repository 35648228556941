import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ScrapersService } from '../_services/scrapers.service';
import { ActivityService } from '../_services/activity.service';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { LayoutService } from '../_services/layout.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { StockXDialogComponent } from './stockx-dialog/stockx-dialog.component';
import { SgDialogComponent } from './sg-dialog/sg-dialog.component';
import { GoatDialogComponent } from './goat-dialog/goat-dialog.component';
import { S3DialogComponent } from './s3-dialog/s3-dialog.component';
import { StockXSchedulerDialogComponent } from './stockx-scheduler-dialog/stockx-scheduler-dialog.component';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-scrapers',
  templateUrl: './scrapers.component.html',
  styleUrls: ['./scrapers.component.scss']
})
export class ScrapersComponent implements OnInit {
  loadingStockX: boolean;
  loadingStadiumGoods: boolean;
  loadingActivity: boolean;
  loadingGoat: boolean;
  loadingStockXScheduler: boolean;
  loadingS3: boolean;
  loadingDedupe: boolean;
  loadingPlaceholder: boolean;

  stockXSchedulerData;
  stadiumGoodsScheduler;
  stockxDate = this.calendar.getToday();
  stockxTime = { hour: 13, minute: 30 };

  headElements = ['#', 'styleId', 'status', 'type', 'notes', 'created'];

  activityLogs;
  successMsg = {
    isShown: false,
    message: ''
  };
  errorMsg = {
    isShown: false,
    message: ''
  };
  stockxForm: FormGroup;
  sgForm: FormGroup;
  s3Form: FormGroup;
  animal: string;
  name: string;

  constructor(
    private scrapersService: ScrapersService,
    private activityService: ActivityService,
    private layout: LayoutService,
    private calendar: NgbCalendar,
    public dialog: MatDialog
  ) {}

  openStockXDialog(): void {
    const dialogRef = this.dialog.open(StockXDialogComponent, {
      width: '375px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('stockXParams found', result);
        this.scrapeStockX(result);
      }
    });
  }


  removeDuplicates(): void {
    this.loadingDedupe = true;
    this.scrapersService.removeDuplicates().subscribe(result => {

      this.loadingDedupe = false;

      this.successMsg = {
        isShown: true,
        message: 'Duplicates removal ran successfully. Please periodically refresh page to see results. '
      };

      this.getActivity();

    }, error => {
      console.error('Error running removeDuplicates: ',)
    });
  }

  removePlaceholders(): void {
    this.loadingPlaceholder = true;

    this.scrapersService.removePlaceholders().subscribe((result: any) => {

      this.loadingPlaceholder = false;

      this.successMsg = {
        isShown: true,
        message: result.message
      };

      this.getActivity();

    }, error => {
      console.error('Error running removePlaceholders: ')
    });
  }


  // Scheduler

  openStockXSchedulerDialog(): void {
    const dialogRef = this.dialog.open(StockXSchedulerDialogComponent, {
      width: '375px'
    });

    dialogRef.afterClosed().subscribe(params => {
      if (params) {
        console.log('stockXParams found', params);
        this.scrapersService.startStockXScheduler(params).subscribe(result => {
          this.getStockXScheduler();
        });
      }
    });
  }

  stopStockXScheduler() {

    this.scrapersService.stopStockXScheduler().subscribe(result => {
      console.log('result', result);
      this.stockXSchedulerData = null;
    });

  }

  stopStadiumGoodsScheduler() {

    this.scrapersService.stopStadiumGoodsScheduler().subscribe(result => {
      console.log('result', result);
      this.stockXSchedulerData = null;
      this.getStadiumGoodsScheduler();
    });

  }

  getStadiumGoodsScheduler() {

    this.scrapersService.getStadiumGoodsScheduler().subscribe((result: any) => {
      console.log('getStadiumGoodsScheduler: ', result);

      if(result.success){

        this.stadiumGoodsScheduler = result;
      } else {
        this.stadiumGoodsScheduler = null;
      }
    });

  }

  getStockXScheduler() {

    this.scrapersService.getStockXScheduler().subscribe((result: any) => {
      console.log('getStockXScheduler: ', result);

      if(result.success){
        this.stockXSchedulerData = result;

      } else {
        this.stockXSchedulerData = null;
      }
    });

  }

  // Open Stadium Goods
  openSgDialog(): void {
    const dialogRef = this.dialog.open(SgDialogComponent, {
      width: '375px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('sgParams found', result);
        this.scrapeSg(result);
      }
    });
  }

  openGoatDialog(): void {
    const dialogRef = this.dialog.open(GoatDialogComponent, {
      width: '375px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('goatParams found', result);
        this.scrapeGoat(result);
      }
    });
  }

  openS3Dialog(): void {
    const dialogRef = this.dialog.open(S3DialogComponent, {
      width: '375px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('s3Params found', result);
        this.scrapeS3(result);
      }
    });
  }

  resetMessages() {
    this.successMsg = {
      isShown: false,
      message: ''
    };
    this.errorMsg = {
      isShown: false,
      message: ''
    };
  }

  scrapeStockX(params) {
    // Create epoch from data
    const epochTime = moment(
      `${params.date.month}-${params.date.day}-${params.date.year} ${params.time.hour}:${params.time.minute}:00`,
      'MM-DD-YYYY HH:mm:ss'
    ).unix();

    console.log(epochTime);

    const data = {
      start_page: Number(params.startPage),
      end_page: Number(params.endPage),
      timestamp: epochTime,
      order: params.order,
      parallel: params.parallel
    };

    this.loadingStockX = true;
    this.resetMessages();
    this.scrapersService.runStockXScraper(data).subscribe(
      response => {
        console.log(response);

        this.successMsg = {
          isShown: true,
          message: 'StockX scraper ran successfully. Please periodically refresh page to see results. '
        };

        this.getActivity();
      },
      error => {
        // this.errorMsg = {
        //   isShown: true,
        //   message: "Requet timed out. Please check AWS Lambda logs for more details."
        // };

        this.successMsg = {
          isShown: true,
          message: 'StockX scraper ran successfully. Please periodically refresh page to see results. '
        };

        console.error(error);
      },
      () => {
        this.loadingStockX = false;
      }
    );
  }

  scrapeS3(params) {
    this.loadingS3 = true;

    const limit = params.limit;

    this.scrapersService.runS3Scraper(limit).subscribe(
      response => {
        this.loadingS3 = false;

        this.successMsg = {
          isShown: true,
          message: 'S3 ran successfully. '
        };

        this.getActivity();
      },
      error => {
        this.loadingS3 = false;

        this.successMsg = {
          isShown: true,
          message: 'Error runnning S3 Migration Scraper. Please periodically refresh page to see results. '
        };

        console.error(error);
      },
      () => {
        this.loadingS3 = false;
      }
    );
  }

  scrapeGoat(params) {
    this.loadingGoat = true;

    const limit = params.limit;

    this.scrapersService.runGoatScraper(limit).subscribe(
      response => {
        this.loadingGoat = false;

        this.successMsg = {
          isShown: true,
          message: 'GOAT scraper ran successfully. '
        };

        this.getActivity();
      },
      error => {
        this.loadingGoat = false;

        this.successMsg = {
          isShown: true,
          message: 'Goat scraper ran successfully. Please periodically refresh page to see results. '
        };

        console.error(error);
      },
      () => {
        this.loadingGoat = false;
      }
    );
  }

  scrapeSg(params) {
    const data = { numShoes: params.numShoes, batchSize:  params.batchSize, minutes: params.minutes,};

    this.loadingStadiumGoods = true;
    this.resetMessages();
    this.scrapersService.runStadiumGoodsScraper(data).subscribe(
      response => {
        console.log(response);

        this.successMsg = {
          isShown: true,
          message: 'Stadium Goods scraper ran successfully. Please periodically refresh page to see results. '
        };

        this.getStadiumGoodsScheduler();
        this.getActivity();

        this.loadingStadiumGoods = false;

      },
      error => {
        this.errorMsg = {
          isShown: true,
          message: 'Error running Stadium Goods scraper. Please check AWS Lambda logs for more details. '
        };

        console.error(error);
        this.loadingStadiumGoods = false;

      }
    );
  }

  getActivity() {
    this.loadingActivity = true;
    this.activityService.findActivity().subscribe(
      response => {
        this.activityLogs = response;
      },
      error => {
        console.error(error);
      },
      () => {
        this.loadingActivity = false;
      }
    );
  }

  ngOnInit() {
    this.layout.showTopbar();

    this.getStockXScheduler();
    this.getStadiumGoodsScheduler();

    this.getActivity();

    this.stockxForm = new FormGroup({
      startPage: new FormControl(1, []),
      endPage: new FormControl(10, []),
      parallel: new FormControl(false, []),
      order: new FormControl('DESC', [])
    });

    this.sgForm = new FormGroup({
      limit: new FormControl(50, [])
    });

    this.s3Form = new FormGroup({
      s3Limit: new FormControl(400, [])
    });
  }
}
