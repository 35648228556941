import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../_services/layout.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  constructor(private layout: LayoutService,
    ) { }

  ngOnInit() {
    this.layout.showTopbar();
  }

}
