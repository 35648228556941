<h1>Create a shoe</h1>

<div class="form-container">

  <form [formGroup]="createShoeForm" (ngSubmit)="onSubmit(createShoeForm.value)">
    <div class="form">

      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Style ID</mat-label>
        <input matInput placeholder="style Id" formControlName="styleId" required />
        <mat-icon *ngIf="!searchingShoe" class="shoe-search" matSuffix (click)="getMarketplaceInfo(createShoeForm.controls.styleId.value)">search</mat-icon>
        <mat-icon *ngIf="searchingShoe" matSuffix>hourglass_bottom</mat-icon>
        <mat-error *ngIf="createShoeForm.controls.styleId.invalid">{{
          getErrorMsg(createShoeForm.controls.styleId, 'Style ID')
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Image Url</mat-label>
        <input matInput placeholder="Image url" formControlName="imageUrl" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title" required />
        <mat-error *ngIf="createShoeForm.controls.title.invalid">{{ getErrorMsg(createShoeForm.controls.title, 'Title') }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name" required />
        <mat-error *ngIf="createShoeForm.controls.name.invalid">{{ getErrorMsg(createShoeForm.controls.name, 'Name') }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Brand</mat-label>
        <input matInput placeholder="brand" formControlName="brand" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Category</mat-label>
        <input matInput placeholder="Category" formControlName="category" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Colorway</mat-label>
        <input matInput placeholder="Colorway" formControlName="colorway" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="men">Men</mat-option>
          <mat-option value="women">Women</mat-option>
          <mat-option value="youth">Youth</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Release date</mat-label>
        <input matInput placeholder="Release date" formControlName="releaseDate" [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Retail Price</mat-label>
        <input matInput placeholder="Retail price" formControlName="retailPrice" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Shoe</mat-label>
        <input matInput placeholder="Shoe" formControlName="shoe" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Short Description</mat-label>
        <input matInput placeholder="Short Description" formControlName="shortDescription" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Ticker symbol</mat-label>
        <input matInput placeholder="Ticker symbol" formControlName="tickerSymbol" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Url key</mat-label>
        <input matInput placeholder="Url key" formControlName="urlKey" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>SKU</mat-label>
        <input matInput placeholder="SKU" formControlName="SKU" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Year</mat-label>
        <input matInput placeholder="Year" formControlName="year" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Nickname</mat-label>
        <input matInput placeholder="nickname" formControlName="nickname" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Designer</mat-label>
        <input matInput placeholder="Designer" formControlName="designer" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Main color</mat-label>
        <input matInput placeholder="Main color" formControlName="mainColor" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Tech</mat-label>
        <input matInput placeholder="Tech" formControlName="tech" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Upper material</mat-label>
        <input matInput placeholder="Upper material" formControlName="upperMaterial" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Silhouette</mat-label>
        <input matInput placeholder="Silhouette" formControlName="silhouette" />
      </mat-form-field>
    </div>

    <button class="btn btn-dark fixed-width-175" type="submit">
      <span *ngIf="!loadingShoe">Create shoe</span>
      <div class="spinner-border" role="status" *ngIf="loadingShoe">
        <span class="sr-only">Loading...</span>
      </div>
    </button>
    <button class="btn btn-gray fixed-width-175" (click)="cancel()">
      Cancel
    </button>
  </form>
</div>
