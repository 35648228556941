import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RedditPost } from '../_models/redditPost';
import { ListingsService } from '../_services/listings.service';
import { ShoesService } from '../_services/shoes.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ShoeImgPopupComponent } from '../shoe-img-popup/shoe-img-popup.component';

@Component({
  selector: 'app-market-listings',
  templateUrl: './market-listings.component.html',
  styleUrls: ['./market-listings.component.scss'],
})
export class MarketListingsComponent implements OnInit, AfterViewInit {
  searchedShoe: string;
  executeSearch = new Subject<string>();
  shoeSubject = new Subject<string>();
  inputTexts: string[] = [];
  isSearchingForShoes = false;

  @ViewChild('box', { static: true }) input: ElementRef;

  imgLink: string;
  selectedShoeId: number;
  shoeSearchControl = new FormControl();
  options: any;
  filteredOptions: Observable<any>;
  shoes$: Observable<any>;

  shoesFound: any[] = [];
  redditPosts: RedditPost[];
  defaultSize: any;
  loading = false;
  isUpdating = false;
  conditions = [
    { id: 1, value: 'DS', name: 'Deadstock' },
    { id: 2, value: 'VNDS', name: 'Very Near Deadstock' },
    { id: 3, value: '10/10', name: '10/10' },
    { id: 4, value: '9/10', name: '9/10' },
    { id: 5, value: '8/10', name: '8/10' },
    { id: 6, value: '7/10', name: '7/10' },
    { id: 7, value: '6/10', name: '6/10' },
    { id: 8, value: '5/10', name: '5/10' },
    { id: 9, value: '4/10', name: '4/10' },
    { id: 10, value: '3/10', name: '3/10' },
    { id: 11, value: '2/10', name: '2/10' },
    { id: 12, value: '1/10', name: '1/10' },
  ];

  tableColumns = ['Image', 'Style ID', 'Title', 'Type', 'Size', 'Condition', 'Price', 'Shoe', 'Linking Button'];
  sizes = [
    { id: 1, value: 4 },
    { id: 2, value: 4.5 },
    { id: 3, value: 5 },
    { id: 4, value: 5.5 },
    { id: 5, value: 6 },
    { id: 6, value: 6.5 },
    { id: 7, value: 7 },
    { id: 8, value: 7.5 },
    { id: 9, value: 8 },
    { id: 10, value: 8.5 },
    { id: 11, value: 9 },
    { id: 12, value: 9.5 },
    { id: 13, value: 10 },
    { id: 14, value: 10.5 },
    { id: 15, value: 11 },
    { id: 16, value: 11.5 },
    { id: 17, value: 12 },
    { id: 18, value: 12.5 },
    { id: 19, value: 13 },
    { id: 20, value: 13.5 },
    { id: 21, value: 14 },
    { id: 22, value: 14.5 },
  ];

  types = [
    {
      id: 'wts',
      value: '[WTS]',
      name: 'WTS (Want to Sell)',
    },
    {
      id: 'wtb',
      value: '[WTB]',
      name: 'WTB (Want to Buy)',
    },
    {
      id: 'wtt',
      value: '[WTT]',
      name: 'WTT (Want to Trade)',
    },
    {
      id: 'announcement',
      value: '[Announcement]',
      name: 'Announcement',
    },
    {
      id: 'scam',
      value: '[SCAM]',
      name: 'SCAM',
    },
  ];

  searchedShoes;
  shoeSearchForm = new FormGroup({
    searchedShoe: new FormControl(''),
  });

  constructor(private listingsService: ListingsService, private shoesService: ShoesService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.loading = true;
    this.listingsService.fetchRedditPosts().subscribe((redditPosts: RedditPost[]) => {
      this.redditPosts = redditPosts;
      this.loading = false;
      console.log(this.redditPosts);
    });

    // Subscribe to search
    // this.shoesService.getSearch().subscribe((result) => {
    //   console.log('getSearch: ', result);
    // });
  }

  ngAfterViewInit(): void {}

  searchShoe(value: string, listing: RedditPost) {
    // Emit value
    listing.loading = true;

    // Create a subject with value if does not exist
    if (!listing.subject) {
      listing.subject = new BehaviorSubject(value);

      listing.subject
        .asObservable()
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          mergeMap((searchValue) => {
            console.log('getSearch: ', searchValue);
            return this.shoesService.searchShoe(searchValue);
            // return of(searchValue)
          })
        )
        .subscribe((result) => {
          console.log('found shoes: ', result);

          this.shoesFound = result;
          listing.loading = false;
        });
    } else {
      listing.subject.next(value);
    }

    // this.shoesService.search(value).subscribe((result)=> {
    //   console.log("Shoe results: ", result);
    // });
    // this.shoeSubject.pipe(
    //   debounceTime(2000),
    //   distinctUntilChanged())
    // .subscribe((shoeSearchText) => {
    //   if (shoeSearchText) {
    //     listing.loading = true;
    //     this.isSearchingForShoes = true;

    //     console.log('INPUT HAS TEXT', shoeSearchText);

    //     this.shoesService.setupShoeSearch().subscribe((shoesObs) => {
    //       shoesObs.subscribe((shoes) => {
    //         console.log('shoes found: ', shoes);

    //         if (shoes.length > 0) {
    //           listing.loading = false;
    //           this.isSearchingForShoes = false;
    //         }

    //         this.shoesFound = shoes;
    //       });
    //     });
    //     this.isSearchingForShoes = false;
    //   } else {
    //     listing.loading = false;
    //     console.log('INPUT DOES NOT HAVE TEXT');
    //   }
    // });
  }

  setShoe(shoeId) {
    this.selectedShoeId = shoeId;
  }

  getShoeName(shoe) {
    return shoe.shoe;
  }

  linkShoeToRedditPost(shoeId, shoeName, listing) {
    listing.shoeId = Number(shoeId);
    listing.shoe = shoeName;

    console.log('Changed Reddit post:', shoeId, listing);

    this.shoesService.linkShoeToRedditPost(listing.id, Number(shoeId)).subscribe(result => {
      console.log('linkShoeToRedditPost response:', result);
    })
  }

  updateRedditPost(listing) {
    listing.loading = true;
    this.shoesService.updateRedditPost(listing).subscribe(
      (result: any) => {
        console.log('Reddit Post linked: ', result);
        listing.shoeTitle = result.data.shoeTitle;
        listing.styleId = result.data.styleId;
        listing.shoeImage = result.data.shoeImage;
        listing.loading = false;
      },
      (error) => {
        console.error('Reddit Post Error: ', error);
        listing.loading = false;
      }
    );
  }

  unlinkShoe(listing) {
    listing.loading = true;
    this.shoesService.unlinkShoe(listing).subscribe((result:any) => {
      listing.loading = true;


      if(result.code == 200){
        console.log("Changing listing...", result.data);
        listing = result.data;
      }
    });
  }

  openDialog(imgLink: string): void {
    this.dialog.open(ShoeImgPopupComponent, {
      data: {
        imgLink,
        width: '500px',
      },
    });
    this.imgLink = imgLink;
  }
}
