import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LayoutService } from '../_services/layout.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-topbar',
  templateUrl: './nav-topbar.component.html',
  styleUrls: ['./nav-topbar.component.scss']
})
export class NavTopbarComponent implements OnInit {
  isSidebarIcon = false;
  isSidebarOpen = false;
  isTopbarShown = false;
  @Output() openCloseSidebar = new EventEmitter<boolean>();

  constructor(
    private layout: LayoutService,
    private auth: AuthenticationService,
    private router: Router,

  ) {}



  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    // Store in service
    this.openCloseSidebar.emit(this.isSidebarOpen);
  }

  logout() {
    this.auth.logout();
    this.layout.resetLayout();
    this.router.navigate(['login']);
  }

  ngOnInit() {
    this.layout.getLayout().subscribe(layout => {
      this.isSidebarIcon = layout.isSidebarIcon;
      this.isSidebarOpen = layout.isSidebarOpen;
      this.isTopbarShown = layout.isTopbarShown;
    });
  }
}
