import { BrowserModule } from '@angular/platform-browser';

// Modules
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

// import { MDBBootstrapModule, WavesModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavTopbarComponent } from './nav-topbar/nav-topbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ShoesListComponent } from './shoes/shoes-list/shoes-list.component';
import { ShoesDetailComponent } from './shoes/shoes-detail/shoes-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/fake-backend';
import { JwtInterceptor } from './_helpers/jwt_interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AlertComponent } from './alert/alert.component';
import { RegisterComponent } from './register/register.component';
import { ShoesTableComponent } from './shared/shoes-table/shoes-table.component';
import { ActivityComponent } from './activity/activity.component';
import { ScrapersComponent } from './scrapers/scrapers.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StockXDialogComponent } from './scrapers/stockx-dialog/stockx-dialog.component';
import { SgDialogComponent } from './scrapers/sg-dialog/sg-dialog.component';
import { GoatDialogComponent } from './scrapers/goat-dialog/goat-dialog.component';
import { S3DialogComponent } from './scrapers/s3-dialog/s3-dialog.component';
import { StockXSchedulerDialogComponent } from './scrapers/stockx-scheduler-dialog/stockx-scheduler-dialog.component';
import { ShoesBarChartComponent } from './charts/shoes-bar-chart/shoes-bar-chart.component';
import { MarketListingsComponent } from './market-listings/market-listings.component';

import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ShoeImgPopupComponent } from './shoe-img-popup/shoe-img-popup.component';
import { CreateShoeComponent } from './create-shoe/create-shoe.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ShoeEditComponent } from './shoes/shoe-edit/shoe-edit.component';
import { AlertService } from './_services/alert.service';

@NgModule({
  declarations: [
    AppComponent,
    NavSidebarComponent,
    NavTopbarComponent,
    DashboardComponent,
    ShoesListComponent,
    ShoesDetailComponent,
    PageNotFoundComponent,
    LoginComponent,
    AlertComponent,
    RegisterComponent,
    ShoesTableComponent,
    ActivityComponent,
    ScrapersComponent,
    StockXDialogComponent,
    StockXSchedulerDialogComponent,
    SgDialogComponent,
    GoatDialogComponent,
    S3DialogComponent,
    ShoesBarChartComponent,
    MarketListingsComponent,
    ShoeImgPopupComponent,
    CreateShoeComponent,
    ShoeEditComponent,
  ],
  imports: [
    // MDBBootstrapModule.forRoot(),
    // WavesModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MaterialModule,
    MomentModule,
    NgbModule,
    ChartsModule,
    MatTableModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
  entryComponents: [StockXDialogComponent, StockXSchedulerDialogComponent, SgDialogComponent, GoatDialogComponent, S3DialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    AlertService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
