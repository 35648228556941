// DC7501-300

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ShoesService } from '../_services/shoes.service';
import * as moment from 'moment';
import { AlertService } from '../_services/alert.service';

interface Sneaker {
  styleId: number;
  imageUrl: string;
  title: string;
  name: string;
  brand: string;
  category: string;
  colorway: string;
  gender: string;
  releaseDate: Date;
  retailPrice: number;
  shoe: string;
  shortDescription: string;
  tickerSymbol: string;
  urlKey: string;
  year: number;
  SKU: number;
  didSearchSKU: boolean;
  isBroken: boolean;
  designer: string;
  mainColor: string;
  tech: string;
  upperMaterial: string;
  silhouette: string;
}



@Component({
  selector: 'app-create-shoe',
  templateUrl: './create-shoe.component.html',
  styleUrls: ['./create-shoe.component.scss'],
})
export class CreateShoeComponent implements OnInit {
  createShoeForm: FormGroup;
  alertMessage;
  searchingShoe: boolean = false;
  loadingShoe: boolean = false;
  dateInfo;

  get imageUrl(): AbstractControl {
    return this.createShoeForm.controls.imageUrl;
  }

  constructor(
    private fb: FormBuilder,
    private shoeService: ShoesService,
    private router: Router,
    private alertService: AlertService) {}

  ngOnInit(): void {
    this.createShoeForm = this.fb.group({
      styleId: ['', [Validators.required]],
      imageUrl: [''],
      title: ['', [Validators.required]],
      name: ['', [Validators.required]],
      brand: [''],
      category: [''],
      colorway: [''],
      gender: [''],
      releaseDate: [''],
      retailPrice: [''],
      shoe: [''],
      shortDescription: [''],
      tickerSymbol: [''],
      urlKey: [''],
      year: [''],
      SKU: [''],
      didSearchSKU: [''],
      isBroken: [''],
      nickname: [''],
      designer: [''],
      mainColor: [''],
      tech: [''],
      upperMaterial: [''],
      silhouette: [''],
    });
  }

  getErrorMsg(control: AbstractControl, input: string) {
    return control.hasError('required') ? `${input} is required` : '';
  }

  getMarketplaceInfo(styleId){

    this.searchingShoe = true;
    this.shoeService.searchShoeOnWeb(styleId).subscribe(shoes => {
      if(shoes.length > 0){
        let releaseDateTime = moment(shoes[0].releaseDateTime, "YYYY-DD-MM").toDate()

        this.createShoeForm.patchValue({
          releaseDate: releaseDateTime,
          name: shoes[0].name
        });

        this.alertService.success(`We found ${shoes[0].name} from web or in database. It has been added to database.`);

        this.createShoeForm.get('name').setValue(shoes[0].name);
        this.createShoeForm.get('imageUrl').setValue(shoes[0].imageUrl);
        this.createShoeForm.get('title').setValue(shoes[0].title);
        this.createShoeForm.get('name').setValue(shoes[0].name);
        this.createShoeForm.get('brand').setValue(shoes[0].brand);
        this.createShoeForm.get('category').setValue(shoes[0].category);
        this.createShoeForm.get('colorway').setValue(shoes[0].colorway);
        this.createShoeForm.get('gender').setValue(shoes[0].gender);
        this.createShoeForm.get('retailPrice').setValue(shoes[0].retailPrice);
        this.createShoeForm.get('shoe').setValue(shoes[0].shoe);
        this.createShoeForm.get('shortDescription').setValue(shoes[0].shortDescription);
        this.createShoeForm.get('tickerSymbol').setValue(shoes[0].tickerSymbol);
        this.createShoeForm.get('urlKey').setValue(shoes[0].urlKey);
        this.createShoeForm.get('year').setValue(shoes[0].year);
        this.createShoeForm.get('SKU').setValue(shoes[0].SKU);
        this.createShoeForm.get('didSearchSKU').setValue(shoes[0].didSearchSKU);
        this.createShoeForm.get('nickname').setValue(shoes[0].nickname);
        this.createShoeForm.get('designer').setValue(shoes[0].designer);
        this.createShoeForm.get('mainColor').setValue(shoes[0].mainColor);
        this.createShoeForm.get('tech').setValue(shoes[0].tech);
        this.createShoeForm.get('upperMaterial').setValue(shoes[0].upperMaterial);
        this.createShoeForm.get('silhouette').setValue(shoes[0].silhouette);

        this.alertService.success(`We found ${shoes[0].name} from web or in database. It has been added to database.`, true);
        this.router.navigate(['/dashboard'])
      }
      this.searchingShoe = false;
    });
  }

  cancel(){
    this.router.navigate(['/dashboard'])
  }

  onSubmit(formValues) {
    this.loadingShoe = true;

    if(this.createShoeForm.invalid){
      this.loadingShoe = false;

      return;
    }

    this.shoeService.createShoe(formValues).subscribe(result => {
      console.log(result);
      this.loadingShoe = false;

      if(result.success == true){
        this.alertMessage = {
          type: "success",
          text: `Shoe created.`
        }
        this.router.navigate(['/dashboard'])
        this.alertService.success(`Shoe created.`, true);

      }

    }, (error)=>{
      this.loadingShoe = false;
      this.alertService.error(error);
    })
  }
}
